import { CdkStep } from '@angular/cdk/stepper';
import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-wizard-step-base',
  template: '',
  styleUrls: ['./wizard-step-base.component.scss'],
})
export abstract class WizardStepBaseComponent {
  public get form(): FormGroup | null {
    return this.cdkStep.stepControl as FormGroup;
  }

  public constructor(private readonly cdkStep: CdkStep) {}

  public getFormControl(name: string): FormControl {
    return this.form!.get(name) as FormControl;
  }
}
