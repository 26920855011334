import { Component, Input, OnInit } from '@angular/core';
import { WizardStepBaseComponent } from '../wizard-step-base/wizard-step-base.component';
import { flatten, isNil } from 'lodash';
import { AbstractControl } from '@angular/forms';

export interface WizardStepOption {
  titleKey: string;
  value: number | number[];
}

@Component({
  selector: 'app-multi-option-wizard-step',
  templateUrl: './multi-option-wizard-step.component.html',
  styleUrls: ['./multi-option-wizard-step.component.scss']
})
export class MultiOptionWizardStepComponent extends WizardStepBaseComponent implements OnInit {

  @Input() public options: WizardStepOption[] = [];
  @Input() public multi: boolean = false;
  public selectedOptions: WizardStepOption[] = [];

  public get formControl(): AbstractControl {
    const firstFormControlName = Object.keys(this.form!.controls)[0];
    return this.form?.get(firstFormControlName)!;
  }

  public get formControlValue(): number[] {
    return this.formControl.value ?? [];
  }

  ngOnInit(): void {
  }

  public selectOption(event: Event, option: WizardStepOption) {
    event.preventDefault();

    if (this.isOptionSelected(option)) {
      if (this.multi) {
        const index = this.selectedOptions.indexOf(option);
        this.selectedOptions.splice(index, 1);
      } else {
        this.selectedOptions = [];
      }
    } else {
      if (this.multi) {
        this.selectedOptions.push(option);
      } else {
        this.selectedOptions = [option];
      }
    }
    this.updateFormControlValue();
  }

  public isOptionSelected(option: WizardStepOption): boolean {
    return this.selectedOptions.includes(option);
  }

  public trackByIndex(index: number): number {
    return index;
  }

  private updateFormControlValue(): void {
    if (this.multi) {
      const values = flatten(this.selectedOptions.map(o => o.value));
      this.formControl.setValue(values);
    } else {
      const value = this.selectedOptions?.[0]?.value;
      this.formControl.setValue(isNil(value) ? null : value);
    }
  }
}
