<app-slime-background></app-slime-background>

<div class="d-flex align-items-center h-100">
  <div class="container-md mt-2 mb-5">
    <div class="card">
      <div class="card-body p-4">
        <app-auth-navigation *ngIf="!signUpPageDisabled"></app-auth-navigation>
        <h2 class="card-title" [ngClass]="{'mt-5': !signUpPageDisabled}">
          {{ 'auth.signup.title' | translate }}
        </h2>
        <small *ngIf="!consultantConnectionDisabled">
          <a href="#" (click)="handleSignUpMonitoringApp($event)">{{
            'auth.signup.link' | translate
          }}</a>
        </small>
        <app-sign-up-form
          class="d-block mt-3"
          [preFilledEmail]="preFilled.email"
          (signUp)="handleSignUpClick($event)"
        ></app-sign-up-form>
      </div>
    </div>
    <app-async-state-overlay [processKeys]="asyncProcessKeys"></app-async-state-overlay>
  </div>
</div>
