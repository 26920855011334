<form [formGroup]="form" name="login" (ngSubmit)="handleLoginClick()" #ngForm="ngForm" novalidate>
  <div class="mb-2">
    <label for="emailAddress" class="form-label">{{
      'auth.forms.email_address' | translate
    }}</label>
    <input
      id="emailAddress"
      name="emailAddress"
      type="email"
      autocomplete="username"
      class="form-control form-control-lg"
      formControlName="emailAddress"
      [class.is-invalid]="ngForm.submitted && form.controls['emailAddress'].invalid"
    />

    <div class="invalid-feedback">
      <div *ngIf="form.controls['emailAddress'].hasError('required')">
        {{ 'auth.forms.email_required_error' | translate }}
      </div>
      <div *ngIf="form.controls['emailAddress'].hasError('pattern')">
        {{ 'auth.forms.email_invalid_error' | translate }}
      </div>
    </div>
  </div>

  <div class="mb-4">
    <label class="form-label">{{ 'auth.forms.password' | translate }}</label>
    <app-password-input
      [class.is-invalid]="ngForm.submitted && form.controls['password'].invalid"
      [isInvalid]="ngForm.submitted && form.controls['password'].invalid"
      controlName="password"
    ></app-password-input>

    <div class="invalid-feedback">
      <div *ngIf="form.controls['password'].hasError('required')">
        {{ 'auth.forms.password_required_error' | translate }}
      </div>
    </div>
  </div>

  <div class="form-check mb-4">
    <input class="form-check-input" type="checkbox" id="keepLoggedIn" />
    <label class="form-check-label" for="keepLoggedIn">
      {{ 'auth.login.keep_logged_in' | translate }}
    </label>
  </div>

  <div class="mb-4">
    <button type="submit" class="btn btn-lg btn-primary w-100">
      {{ 'auth.login.login' | translate }}
    </button>
  </div>

  <div class="mb-4">
    <a href="#" (click)="handleResetClick($event)">{{
      'auth.login.forgot_password' | translate
    }}</a>
  </div>
</form>
