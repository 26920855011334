import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { relativeAccountRoutes } from './relative-account.routes';

@NgModule({
  imports: [
    RouterModule.forChild(relativeAccountRoutes),
  ],
})
export class AppRelativeAccountModule {}
