import { Routes } from '@angular/router';
import {
  LoggedOutGuard,
  LoggedInGuard,
  MAIN_NAVIGATION_PATH_ELEMENTS,
  MAIN_NAVIGATION_PATHS,
  LegalNoticeComponent,
  MyConsultationComponent,
  PrivacyPolicyComponent,
  ProfileComponent,
  UnsubscribeUserPageComponent,
  ConsentAgreementComponent,
  NoCoreDataSubmittedGuard,
  AddMonitoringUserComponent
} from 'ngx-esprio-shared';
import { DashboardComponent } from './views/dashboard-ui/dashboard.components';
import { LandingPageComponent } from './views/landing-page-ui/landing-page.component';

export const routes: Routes = [
  {
    path: `${MAIN_NAVIGATION_PATHS.root.landingPage}`,
    canActivate: [LoggedOutGuard],
    component: LandingPageComponent,
  },
  {
    path: `${MAIN_NAVIGATION_PATHS.root.dashboard}`,
    canActivate: [LoggedInGuard, NoCoreDataSubmittedGuard],
    component: DashboardComponent,
  },
  {
    path: `${MAIN_NAVIGATION_PATHS.root.consultation}`,
    canActivate: [LoggedInGuard],
    children: [
      {
        path: ``,
        component: MyConsultationComponent,
      },
      {
        path: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.addMonitoringUser}`,
        component: AddMonitoringUserComponent,
      },
    ],
  },
  {
    path: `${MAIN_NAVIGATION_PATHS.root.unsubscribeNotification}`,
    canActivate: [LoggedInGuard, NoCoreDataSubmittedGuard],
    component: UnsubscribeUserPageComponent,
  },
  {
    path: `${MAIN_NAVIGATION_PATHS.root.legalNotice}`,
    component: LegalNoticeComponent,
  },
  {
    path: `${MAIN_NAVIGATION_PATHS.root.privacyPolicy}`,
    component: PrivacyPolicyComponent,
  },
  {
    path: `${MAIN_NAVIGATION_PATHS.root.consentAgreement}`,
    component: ConsentAgreementComponent,
  },
  {
    path: `${MAIN_NAVIGATION_PATHS.root.profile}`,
    canActivate: [LoggedInGuard, NoCoreDataSubmittedGuard],
    component: ProfileComponent,
  },
  {
    path: '**',
    redirectTo: `${MAIN_NAVIGATION_PATHS.root.dashboard}`,
  },
];
