import { Routes } from '@angular/router';
import { LoginPageComponent } from './login/login-page/login-page.component';
import { SignUpPageComponent } from './sign-up/sign-up-page/sign-up-page.component';
import { SignUpSuccessfulPageComponent } from './sign-up-successful-page/sign-up-successful-page.component';
import { SignUpConfirmPageComponent } from './sign-up-confirm-page/sign-up-confirm-page.component';
import { EditUserCoreDataPageComponent } from './edit-user-core-data-page/edit-user-core-data-page.component';
import { AUTH_UI_PATH_ELEMENTS, CoreDataSubmittedGuard, EditPasswordPageComponent, EditSubscriptionsPageComponent, LoggedInGuard, LoggedOutGuard, RequestPasswordResetComponent, ResetPasswordPageComponent } from 'ngx-esprio-shared';

export const routes: Routes = [
  {
    path: AUTH_UI_PATH_ELEMENTS.rootElement,
    children: [
      {
        path: AUTH_UI_PATH_ELEMENTS.root.loginElement,
        component: LoginPageComponent,
        canActivate: [LoggedOutGuard],
      },
      {
        path: AUTH_UI_PATH_ELEMENTS.root.signUpElement,
        component: SignUpPageComponent,
        canActivate: [LoggedOutGuard],
      },
      {
        path: AUTH_UI_PATH_ELEMENTS.root.signUpSuccessElement,
        component: SignUpSuccessfulPageComponent,
      },
      {
        path: AUTH_UI_PATH_ELEMENTS.root.signUpConfirmElement,
        component: SignUpConfirmPageComponent,
      },
      {
        path: `${AUTH_UI_PATH_ELEMENTS.root.passwordResetElement}/${AUTH_UI_PATH_ELEMENTS.root.passwordReset.requestElement}`,
        component: RequestPasswordResetComponent,
        canActivate: [LoggedOutGuard],
      },
      {
        path: `${AUTH_UI_PATH_ELEMENTS.root.passwordResetElement}/${AUTH_UI_PATH_ELEMENTS.root.passwordReset.resetElement}`,
        component: ResetPasswordPageComponent,
      },
      {
        path: AUTH_UI_PATH_ELEMENTS.root.editPasswordElement,
        component: EditPasswordPageComponent,
      },
      {
        path: AUTH_UI_PATH_ELEMENTS.root.editUserCoreDataElement,
        canActivate: [LoggedInGuard, CoreDataSubmittedGuard],
        component: EditUserCoreDataPageComponent,
      },
      {
        path: AUTH_UI_PATH_ELEMENTS.root.editSubscriptionsElement,
        canActivate: [LoggedInGuard],
        component: EditSubscriptionsPageComponent,
      },
    ],
  },
];
