<app-slime-background></app-slime-background>

<div class="d-flex align-items-center h-100">
  <div class="container-md mt-2 mb-5">
    <div class="card">
      <div class="card-body p-4">
        <h2 class="card-title mt-3 mb-5">
          {{ 'auth.signup.post_registration_title' | translate }}
        </h2>

        <div class="text-center mb-5">
          <img
            src="assets/images/ConfirmationEmail.svg"
            alt="{{ 'auth.signup.registration_thank_you' | translate }}"
          />
        </div>

        <p class="mb-3">
          {{ 'auth.signup.post_registration_message' | translate : { email } }}
        </p>

        <p>
          {{ 'auth.signup.no_email' | translate }}
          <span class="btn-link cursor-pointer"
                (click)="handleResendConfirmation($event)">{{ 'auth.signup.resend' | translate }}</span>
        </p>
      </div>
    </div>
  </div>
</div>
