<div *ngIf="selectedLandingPage$ | async as selectedLandingPage">
  <div class="top-section position-relative d-flex flex-column mt-5">
    <div
      class="top-section-slime d-none d-xl-block position-absolute w-50 vh-100"
      [class]="selectedLandingPage"
    ></div>
    <div
      class="container-xxl d-xl-block position-relative d-flex flex-column justify-content-center"
    >
      <img class="logo mt-5" src="assets/images/esprio-nutrition-logo.svg" />
      <h1 class="text headline">
        {{ 'landing_page.top_section.headline_' + selectedLandingPage | translate }}
      </h1>
      <h3 class="text sub-headline">
        {{ 'landing_page.top_section.sub_headline' | translate }}
      </h3>
      <button
        class="btn btn-lg btn-primary"
        *ngIf="(selectedLandingPage$ | async) === landingpage.User"
        (click)="handleLoginClick()"
      >
        {{ 'landing_page.sign_in' | translate }}
      </button>

      <button
        class="btn btn-lg btn-primary"
        *ngIf="(selectedLandingPage$ | async) !== landingpage.User"
        (click)="handleSignUpMonitoringApp($event)"
      >
        {{ 'landing_page.sign_up' | translate }}
      </button>

      <ng-container *ngIf="(selectedLandingPage$ | async) === landingpage.User">
        <button class="btn btn-link pt-2" (click)="handleStartMedipolisLane()">
          {{ 'landing_page.medipolis_lane' | translate }}
        </button>
      </ng-container>
    </div>
    <div class="top-section-slime-mobile w-100 d-block" [class]="selectedLandingPage"></div>
  </div>
  <div class="esprio-free position-relative m-auto">
    <div class="floating-svg d-none d-xl-block position-absolute">
      <img src="assets/images/esprio-free-lines.svg" />
    </div>
    <div class="doctors-svg d-none d-xl-block position-absolute">
      <img src="assets/images/esprio-free-doctors.svg" />
    </div>
    <div
      class="container-xxl free-text d-block d-flex justify-content-end position-relative text-center"
    >
      <div class="col-xl-5 col-10">
        <h1 class="text-white text-center">
          {{ 'landing_page.esprio_free.headline_before' | translate }}
          <span class="pops">{{ 'landing_page.esprio_free.free' | translate }}</span>
          {{ 'landing_page.esprio_free.headline_after' | translate }}
        </h1>
      </div>
    </div>
  </div>
  <ng-container
    *ngIf="
      (selectedLandingPage$ | async) === 'doctor' || (selectedLandingPage$ | async) === 'advisor'
    "
  >
    <div class="small-info">
      <div class="container-xxl d-md-flex flex-row justify-content-between">
        <div
          class="info-container d-flex flex-column justify-content-center text-center h-100 col-2"
        >
          <img class="align-self-center" src="assets/images/small-info4.svg" />
          <p class="text">{{ 'landing_page.small_info.text4' | translate }}</p>
        </div>
        <div
          class="info-container d-flex flex-column justify-content-center text-center h-100 col-2"
        >
          <img class="align-self-center" src="assets/images/small-info5.svg" />
          <p class="text">{{ 'landing_page.small_info.text5' | translate }}</p>
        </div>
        <div
          class="info-container d-flex flex-column justify-content-center text-center h-100 col-2"
        >
          <img class="align-self-center" src="assets/images/small-info6.svg" />
          <p class="text">{{ 'landing_page.small_info.text6' | translate }}</p>
        </div>
        <div
          class="info-container d-flex flex-column justify-content-center text-center h-100 col-2"
        >
          <img class="align-self-center" src="assets/images/small-info7.svg" />
          <p class="text">{{ 'landing_page.small_info.text7' | translate }}</p>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="(selectedLandingPage$ | async) === 'user'">
    <div class="small-info">
      <div class="container-xxl d-md-flex flex-row justify-content-between">
        <div
          class="info-container d-flex flex-column justify-content-center text-center h-100 col-3"
        >
          <img class="align-self-center" src="assets/images/small_info1.svg" />
          <p class="headline">
            {{ 'landing_page.small_info.headline1_' + selectedLandingPage | translate }}
          </p>
          <p class="text">
            {{ 'landing_page.small_info.text1' | translate }}
          </p>
        </div>
        <div
          class="info-container d-flex flex-column justify-content-center text-center h-100 col-3"
        >
          <img class="align-self-center" src="assets/images/small_info2.svg" />
          <p class="headline">
            {{ 'landing_page.small_info.headline2_' + selectedLandingPage | translate }}
          </p>
          <p class="text">
            {{ 'landing_page.small_info.text2_' + selectedLandingPage | translate }}
          </p>
        </div>
        <div
          class="info-container d-flex flex-column justify-content-center text-center h-100 col-3"
        >
          <img class="align-self-center" src="assets/images/small_info3.svg" />
          <p class="headline">
            {{ 'landing_page.small_info.headline3_' + selectedLandingPage | translate }}
          </p>
          <p class="text">
            {{ 'landing_page.small_info.text3_' + selectedLandingPage | translate }}
          </p>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="phone-mockup position-relative m-auto">
    <div
      class="phone-mockup-slime d-none d-xl-block position-absolute"
      [class]="selectedLandingPage"
    ></div>
    <div class="container-xxl d-flex d-block justify-content-end position-relative">
      <div class="col-xl-5 col-10">
        <h1 class="headline text-white">
          {{ 'landing_page.phone_mockup.headline_' + selectedLandingPage | translate }}
        </h1>
        <p class="text">
          {{ 'landing_page.phone_mockup.text_' + selectedLandingPage | translate }}
        </p>
        <button
          class="btn btn-primary w-100"
          *ngIf="(selectedLandingPage$ | async) === landingpage.User"
          (click)="handleLoginClick()"
        >
          {{ 'landing_page.sign_in' | translate }}
        </button>

        <button
          class="btn btn-primary w-100"
          *ngIf="(selectedLandingPage$ | async) !== landingpage.User"
          (click)="handleSignUpMonitoringApp($event)"
        >
          {{ 'landing_page.sign_up' | translate }}
        </button>
        <ng-container *ngIf="(selectedLandingPage$ | async) === landingpage.User">
          <button class="btn btn-link pt-2" (click)="handleStartMedipolisLane()">
            {{ 'landing_page.medipolis_lane' | translate }}
          </button>
        </ng-container>
      </div>
    </div>
    <div class="phone-mockup-slime-mobile d-block" [class]="selectedLandingPage"></div>
  </div>
  <ng-container
    *ngIf="
      (selectedLandingPage$ | async) === 'doctor' || (selectedLandingPage$ | async) === 'advisor'
    "
  >
    <div class="steps-process-mobile">
      <div class="d-md-none d-block d-flex flex-column align-items-center flex-wrap mb-2">
        <h1 class="headline mb-6">
          {{ 'landing_page.steps_process.headline' | translate }}
        </h1>
        <img src="assets/images/step1.svg" />
        <p class="text1 text-center">
          {{ 'landing_page.steps_process.text1' | translate }}
        </p>
        <img src="assets/images/step2.svg" />
        <p class="text3 text-center">{{ 'landing_page.steps_process.text2' | translate }}</p>
        <img src="assets/images/step3.svg" />
        <p class="text2 text-center">
          {{ 'landing_page.steps_process.text3' | translate }}
        </p>
        <img src="assets/images/step4.svg" />
        <p class="text4 text-center">
          {{ 'landing_page.steps_process.text4' | translate }}
        </p>
      </div>
    </div>
    <div class="steps-process-desktop d-flex justify-content-center">
      <div class="d-md-block d-none">
        <h1 class="headline text-center">
          {{ 'landing_page.steps_process.headline' | translate }}
        </h1>
        <div class="container-fluid">
          <div class="row">
            <div class="col-3 d-flex justify-content-center">
              <p class="text1 text-center">
                {{ 'landing_page.steps_process.text1' | translate }}
              </p>
            </div>
            <div class="col-3"></div>
            <div class="col-3 d-flex justify-content-center">
              <p class="text3 text-center">
                {{ 'landing_page.steps_process.text2' | translate }}
              </p>
            </div>
            <div class="col-3"></div>
          </div>
          <div class="row">
            <img src="assets/images/process.svg" />
          </div>
          <div class="row">
            <div class="col-3"></div>
            <div class="col-3 d-flex justify-content-center">
              <p class="text2 text-center">
                {{ 'landing_page.steps_process.text3' | translate }}
              </p>
            </div>
            <div class="col-3"></div>
            <div class="col-3 d-flex justify-content-center">
              <p class="text4 text-center">
                {{ 'landing_page.steps_process.text4' | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="usability-section d-flex flex-column justify-content-center align-items-center">
      <div class="col-xl-5 col-10 text-center">
        <h1 class="headline-top text-center">
          {{ 'landing_page.usability_section.headline' | translate }}
        </h1>
        <p class="text sub-headline-top text-center">
          {{ 'landing_page.usability_section.sub_headline_' + selectedLandingPage | translate }}
        </p>
      </div>
      <div class="container-xxl d-md-flex position-relative text-center justify-content-between">
        <div class="left-div">
          <img src="assets/images/mockup1.png" />
          <h1 class="text headline">
            {{ 'landing_page.usability_section.text_headline1_' + selectedLandingPage | translate }}
          </h1>
          <p class="text">
            {{ 'landing_page.usability_section.text1_' + selectedLandingPage | translate }}
          </p>
        </div>
        <div>
          <img src="assets/images/mockup2.png" />
          <h1 class="text headline">
            {{ 'landing_page.usability_section.text_headline2_' + selectedLandingPage | translate }}
          </h1>
          <p class="text">
            {{ 'landing_page.usability_section.text2_' + selectedLandingPage | translate }}
          </p>
        </div>
      </div>
    </div>
    <div class="small-info">
      <div class="container-xxl d-md-flex flex-row justify-content-between">
        <div
          class="info-container d-flex flex-column justify-content-center text-center h-100 col-3"
        >
          <img class="align-self-center" src="assets/images/small_info1.svg" />
          <p class="headline">
            {{ 'landing_page.small_info.headline1_' + selectedLandingPage | translate }}
          </p>
          <p class="text">
            {{ 'landing_page.small_info.text1' | translate }}
          </p>
        </div>
        <div
          class="info-container d-flex flex-column justify-content-center text-center h-100 col-3"
        >
          <img class="align-self-center" src="assets/images/small_info2.svg" />
          <p class="headline">
            {{ 'landing_page.small_info.headline2_' + selectedLandingPage | translate }}
          </p>
          <p class="text">
            {{ 'landing_page.small_info.text2_' + selectedLandingPage | translate }}
          </p>
        </div>
        <div
          class="info-container d-flex flex-column justify-content-center text-center h-100 col-3"
        >
          <img class="align-self-center" src="assets/images/small_info3.svg" />
          <p class="headline">
            {{ 'landing_page.small_info.headline3_' + selectedLandingPage | translate }}
          </p>
          <p class="text">
            {{ 'landing_page.small_info.text3_' + selectedLandingPage | translate }}
          </p>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="(selectedLandingPage$ | async) === 'user'">
    <div class="picture-section position-relative">
      <div class="slime3 position-absolute d-none d-xl-block" id="slime3"></div>
      <div class="container-xxl d-flex flex-column align-items-center position-relative">
        <div class="col-xl-5 col-10 text-center">
          <h1 class="headline text-white">
            {{ 'landing_page.picture_section.headline' | translate }}
          </h1>
          <p class="text">{{ 'landing_page.picture_section.text' | translate }}</p>
        </div>
        <div class="picture-container d-flex flex-column justify-content-center align-items-center">
          <div class="content d-flex justify-content-between">
            <img class="col-xl-6 col-10" src="assets/images/landingpage_picture1.png" alt="img" />
            <div class="picture-info col-xl-5 col-10 mt-auto">
              <h3 class="headline">
                {{ 'landing_page.picture_section.picture1_headline' | translate }}
              </h3>
              <p class="text">{{ 'landing_page.picture_section.picture1_text' | translate }}</p>
            </div>
          </div>
          <div class="content d-flex justify-content-between">
            <div class="picture-info col-xl-5 col-10 order-2 order-xl-1 mt-auto">
              <h3 class="headline">
                {{ 'landing_page.picture_section.picture2_headline' | translate }}
              </h3>
              <p class="text">{{ 'landing_page.picture_section.picture2_text' | translate }}</p>
            </div>
            <img
              class="col-xl-6 col-10 order-1 order-xl-2"
              src="assets/images/landingpage_picture2.png"
              alt="img"
            />
          </div>
          <div class="content d-flex justify-content-between">
            <img class="col-xl-6 col-10" src="assets/images/landingpage_picture3.png" alt="img" />
            <div class="picture-info col-xl-5 col-10 mt-auto">
              <h3 class="headline">
                {{ 'landing_page.picture_section.picture3_headline' | translate }}
              </h3>
              <p class="text">{{ 'landing_page.picture_section.picture3_text' | translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="small-info">
      <div class="container-xxl d-md-flex flex-row justify-content-between">
        <div
          class="info-container d-flex flex-column justify-content-center text-center h-100 col-2"
        >
          <img class="align-self-center" src="assets/images/small-info4.svg" />
          <p class="text">{{ 'landing_page.small_info.text4' | translate }}</p>
        </div>
        <div
          class="info-container d-flex flex-column justify-content-center text-center h-100 col-2"
        >
          <img class="align-self-center" src="assets/images/small-info5.svg" />
          <p class="text">{{ 'landing_page.small_info.text5' | translate }}</p>
        </div>
        <div
          class="info-container d-flex flex-column justify-content-center text-center h-100 col-2"
        >
          <img class="align-self-center" src="assets/images/small-info6.svg" />
          <p class="text">{{ 'landing_page.small_info.text6' | translate }}</p>
        </div>
        <div
          class="info-container d-flex flex-column justify-content-center text-center h-100 col-2"
        >
          <img class="align-self-center" src="assets/images/small-info7.svg" />
          <p class="text">{{ 'landing_page.small_info.text7' | translate }}</p>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="faq position-relative">
    <div class="slime4 position-absolute d-none d-xl-block" id="slime4"></div>
    <div class="container-xxl">
      <h1 class="headline text-center">
        {{ 'landing_page.faq_section.headline' | translate }}
      </h1>
      <div class="card">
        <div class="question-row">
          <div class="question d-flex justify-content-between w-100" (click)="toggleAnswer($event)">
            <h3 class="question-headline m-0 align-self-center">
              {{ 'landing_page.faq_section.question1_' + selectedLandingPage | translate }}
            </h3>
            <img class="plus-icon" src="assets/images/plus_icon.svg" />
            <img class="minus-icon hidden" src="assets/images/minus_icon.svg" />
          </div>
          <div class="answer h-auto w-100 hidden">
            <p class="answer-text">
              {{ 'landing_page.faq_section.answer1_' + selectedLandingPage | translate }}
            </p>
          </div>
        </div>
        <div class="question-row">
          <div class="question d-flex justify-content-between w-100" (click)="toggleAnswer($event)">
            <h3 class="question-headline m-0 align-self-center">
              {{ 'landing_page.faq_section.question2_' + selectedLandingPage | translate }}
            </h3>
            <img class="plus-icon" src="assets/images/plus_icon.svg" />
            <img class="minus-icon hidden" src="assets/images/minus_icon.svg" />
          </div>
          <div class="answer h-auto w-100 hidden">
            <p
              class="answer-text"
              [innerHTML]="'landing_page.faq_section.answer2_' + selectedLandingPage | translate"
            ></p>
          </div>
        </div>
        <div class="question-row">
          <div class="question d-flex justify-content-between w-100" (click)="toggleAnswer($event)">
            <h3 class="question-headline m-0 align-self-center">
              {{ 'landing_page.faq_section.question3_' + selectedLandingPage | translate }}
            </h3>
            <img class="plus-icon" src="assets/images/plus_icon.svg" />
            <img class="minus-icon hidden" src="assets/images/minus_icon.svg" />
          </div>
          <div class="answer h-auto w-100 hidden">
            <p class="answer-text">
              {{ 'landing_page.faq_section.answer3_' + selectedLandingPage | translate }}
            </p>
          </div>
        </div>
        <div class="question-row">
          <div class="question d-flex justify-content-between w-100" (click)="toggleAnswer($event)">
            <h3 class="question-headline m-0 align-self-center">
              {{ 'landing_page.faq_section.question4_' + selectedLandingPage | translate }}
            </h3>
            <img class="plus-icon" src="assets/images/plus_icon.svg" />
            <img class="minus-icon hidden" src="assets/images/minus_icon.svg" />
          </div>
          <div class="answer h-auto w-100 hidden">
            <p class="answer-text">
              {{ 'landing_page.faq_section.answer4_' + selectedLandingPage | translate }}
            </p>
          </div>
        </div>
        <div class="question-row">
          <div class="question d-flex justify-content-between w-100" (click)="toggleAnswer($event)">
            <h3 class="question-headline m-0 align-self-center">
              {{ 'landing_page.faq_section.question5_' + selectedLandingPage | translate }}
            </h3>
            <img class="plus-icon" src="assets/images/plus_icon.svg" />
            <img class="minus-icon hidden" src="assets/images/minus_icon.svg" />
          </div>
          <div class="answer h-auto w-100 hidden">
            <p class="answer-text">
              {{ 'landing_page.faq_section.answer5_' + selectedLandingPage | translate }}
            </p>
          </div>
        </div>
        <div class="question-row">
          <div class="question d-flex justify-content-between w-100" (click)="toggleAnswer($event)">
            <h3 class="question-headline m-0 align-self-center">
              {{ 'landing_page.faq_section.question6_' + selectedLandingPage | translate }}
            </h3>
            <img class="plus-icon" src="assets/images/plus_icon.svg" />
            <img class="minus-icon hidden" src="assets/images/minus_icon.svg" />
          </div>
          <div class="answer h-auto w-100 hidden">
            <p class="answer-text">
              {{ 'landing_page.faq_section.answer6_' + selectedLandingPage | translate }}
              <a
                *ngIf="(selectedLandingPage$ | async) !== landingpage.User"
                (click)="handleSignUpMonitoringApp($event)"
              >
                {{ 'landing_page.sign_up' | translate }}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="testimonials d-flex flex-column justify-content-center align-items-center">
    <div class="col-xl-5 col-10 text-center">
      <h1 class="headline">{{ 'landing_page.testimonials.headline' | translate }}</h1>
      <p class="text">{{ 'landing_page.testimonials.text' | translate }}</p>
    </div>
    <div class="slider-padding">
      <div class="slider d-flex" #slider>
        <div class="card" *ngFor="let slide of slidesOrder; let i = index">
          <div
            class="image-container d-flex justify-content-center align-items-center align-self-center"
          >
            <img
              class="image w-auto h-100"
              [src]="'/assets/images/testimonial-picture/' + slide + '_profile.png'"
              (error)="noImage($event)"
            />
          </div>
          <div class="linear-gradient-border position-absolute"></div>
          <h3 class="name text-center d-inline-block">
            {{ 'landing_page.testimonials.' + slide + '_headline' | translate }}
          </h3>
          <p class="description text-center align-self-center">
            {{ 'landing_page.testimonials.' + slide + '_description' | translate }}
          </p>
          <p class="text text-center px-2">
            {{ 'landing_page.testimonials.' + slide + '_text' | translate }}
          </p>
        </div>
      </div>
    </div>
    <div class="navigation d-flex justify-content-center">
      <div
        class="prev-bg d-flex justify-content-center align-items-center cursor-pointer"
        (click)="prevSlide()"
      >
        <img class="prev" src="assets/images/prev_arrow.svg" />
      </div>
      <div
        class="next-bg d-flex justify-content-center align-items-center cursor-pointer"
        (click)="nextSlide()"
      >
        <img class="next" src="assets/images/next_arrow.svg" />
      </div>
    </div>
  </div>
  <div class="contacts">
    <div class="container-xxl d-flex flex-column align-items-center w-75">
      <h2 class="text-center">{{ 'landing_page.contacts.headline' | translate }}</h2>
      <p class="text-center">
        {{ 'landing_page.contacts.text' | translate }}
        {{ 'landing_page.contacts.email' | translate }}
      </p>
      <a
        class="btn btn-lg btn-primary"
        href="mailto: {{ 'landing_page.contacts.email' | translate }}"
      >
        {{ 'landing_page.contacts.button' | translate }}
      </a>
    </div>
  </div>
</div>
