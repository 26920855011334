<ul class="nav nav-tabs nav-fill">
  <li class="nav-item">
    <a routerLink="{{ AUTH_UI_PATHS.root.login }}" routerLinkActive="active" class="nav-link">
      {{ 'auth.login.login' | translate }}
    </a>
  </li>
  <li class="nav-item">
    <a routerLink="{{ AUTH_UI_PATHS.root.signUp }}" routerLinkActive="active" class="nav-link">
      {{ 'auth.signup.signup' | translate }}
    </a>
  </li>
</ul>
