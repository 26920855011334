import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { debounceTime, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { Actions } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthSignUpActions, BaseAppState, ObservableComponent, getSignUpEmail } from 'ngx-esprio-shared';

@Component({
  selector: 'app-sign-up-successful-page',
  templateUrl: './sign-up-successful-page.component.html',
})
export class SignUpSuccessfulPageComponent extends ObservableComponent implements OnInit {
  public email: string | null = null;
  public resendConfirmationLinkSuccess$ = this.actions$.pipe(
    filter((action) => action.type === AuthSignUpActions.signUpResendConfirmationSuccess.type),
    map(() => true)
  );

  constructor(
    private readonly store: Store<BaseAppState>,
    private readonly actions$: Actions,
    private readonly toastr: ToastrService,
    private readonly trasnlateService: TranslateService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.observeEmail();
    this.observeSendEmailSuccess();
  }

  private observeEmail() {
    this.store
      .select(getSignUpEmail)
      .pipe(
        filter((email) => Boolean(email)),
        tap((email: string) => {
          // hide some parts of the email to increase data privacy
          this.email = email.replace(/(.)(.*?)(@)(.*)/, '$1*****$3$4');
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  handleResendConfirmation(event: MouseEvent) {
    event.preventDefault();
    this.store
      .select(getSignUpEmail)
      .pipe(
        take(1),
        debounceTime(300),
        tap((email: string) => {
          this.store.dispatch(AuthSignUpActions.signUpResendConfirmation({ email }));
        })
      )
      .subscribe();
  }

  private observeSendEmailSuccess() {
    this.resendConfirmationLinkSuccess$
      .pipe(
        takeUntil(this.ngDestroy$),
        tap(() => {
          this.toastr.success(this.trasnlateService.instant('auth.signup.email_sent'));
        })
      )
      .subscribe();
  }
}
