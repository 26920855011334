<div class="container">
  <div class="row">
    <div class="col-lg-8 offset-lg-2">
      <div>
        <h1 class="h5 text-center text-white mt-5">
          {{ 'auth.signup.registration_thank_you' | translate }}
        </h1>
      </div>
      <div class="text-center text-white mb-5">
        {{ 'auth.signup.manual_redirect' | translate }}
      </div>
      <div class="d-flex justify-content-center">
        <button class="btn btn-link" (click)="handleGoToCoreData()">
          {{ 'auth.signup.redirect_link' | translate }}
        </button>
      </div>
      <div>
        <h1 class="h5 text-center text-white mt-5">
          {{ 'auth.signup.welcome_text_headline' | translate }}
        </h1>
      </div>
      <div class="text-center text-white mb-5">
        {{ 'auth.signup.welcome_text_content1' | translate }}
      </div>
      <div class="text-center text-white mb-5">
        {{ 'auth.signup.welcome_text_content2' | translate }}
      </div>
      <div class="d-flex justify-content-center">
        <button class="btn btn-link" (click)="handleGoToCoreData()">
          {{ 'auth.signup.welcome_text_end' | translate }}
        </button>
      </div>
    </div>
  </div>
  <app-async-state-overlay [processKeys]="asyncProcessKeys"></app-async-state-overlay>
</div>
