import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { surveyRoutes } from './survey.routes';


@NgModule({
  imports: [
    RouterModule.forChild(surveyRoutes),
  ],
})
export class AppSurveyModule {}
