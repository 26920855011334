import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BaseAppState, getCodeQueryParam, getUserIdQueryParam, goToLogin, goToUpdateUserCoreData, signUpConfirmation } from 'ngx-esprio-shared';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-sign-up-confirm-page',
  templateUrl: './sign-up-confirm-page.component.html',
  styleUrls: ['./sign-up-confirm-page.component.scss'],
})
export class SignUpConfirmPageComponent implements OnInit {
  readonly asyncProcessKeys = [signUpConfirmation.type];

  private userId$ = this.store.pipe(select(getUserIdQueryParam));
  private code$ = this.store.pipe(select(getCodeQueryParam));

  constructor(private store: Store<BaseAppState>) {}

  ngOnInit() {
    this.confirmSignUp();
  }

  public handleGoToCoreData() {
    this.store.dispatch(goToUpdateUserCoreData());
  }

  private confirmSignUp() {
    combineLatest([this.userId$, this.code$])
      .pipe(take(1))
      .subscribe(([userId, code]) => {
        if (!userId || !code) {
          this.store.dispatch(goToLogin());
          return;
        }

        this.store.dispatch(signUpConfirmation({ userId: userId, code: code }));
      });
  }
}
