import { Routes } from '@angular/router';
import { LoggedInGuard, SurveyAnalysisPageComponent, SurveyPageComponent, SURVEY_PATHS } from 'ngx-esprio-shared';

export const surveyRoutes: Routes = [
  {
    path: SURVEY_PATHS.root.survey,
    canActivate: [LoggedInGuard],
    component: SurveyPageComponent,
  },
  {
    path: SURVEY_PATHS.root.surveyAnalysis,
    canActivate: [LoggedInGuard],
    component: SurveyAnalysisPageComponent,
  },
];
