<app-slime-background></app-slime-background>
<div class="d-flex align-items-center h-100">
  <div class="container-md mt-3 mb-5">
    <form [formGroup]="form">
      <ng-container *ngIf="steps$ | async as steps">
        <!-- wizard -->
        <app-user-core-data-wizard
          [stepData]="steps"
          (selectionChange)="handleStepChange()"
          (finish)="handleFinishWizard()"
        >
          <ng-container *ngFor="let step of steps; let i = index">
            <!-- steps -->
            <cdk-step [stepControl]="form.controls[step.formGroupName!]">
              <app-personal-info-wizard-step *ngIf="i === 1"></app-personal-info-wizard-step>
              <app-multi-option-wizard-step
                *ngIf="i > 1"
                [options]="step.options || []"
                [multi]="step.multiSelect || false"
              >
              </app-multi-option-wizard-step>
            </cdk-step>
          </ng-container>
        </app-user-core-data-wizard>
      </ng-container>
    </form>
  </div>
</div>

<app-async-state-overlay [processKeys]="asyncProcessKeys"></app-async-state-overlay>
