import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BaseAppState, ModalsActions, emailPatternValidator, goToLogin, matchingPasswordsValidator, passwordLowercaseValidator, passwordNumberValidator, passwordSpecialValidator, passwordUppercaseValidator } from 'ngx-esprio-shared';
import { SignUpFormValue } from './sign-up-form-value.model';

@Component({
  selector: 'app-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss'],
})
export class SignUpFormComponent implements OnInit {
  @Output('signUp') signUpEmitter = new EventEmitter<SignUpFormValue>();

  @Input() preFilledEmail: string;

  form: FormGroup = new FormGroup(
    {
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      emailAddress: new FormControl('', [Validators.required, emailPatternValidator]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        passwordUppercaseValidator,
        passwordLowercaseValidator,
        passwordNumberValidator,
        passwordSpecialValidator,
      ]),
      passwordConfirmation: new FormControl('', [Validators.required]),
      hasAcknowledgedPrivacyPolicy: new FormControl(false, Validators.requiredTrue),
      hasAcknowledgedDisclaimer: new FormControl(false, Validators.requiredTrue),
    },
    {
      validators: [matchingPasswordsValidator],
    }
  );

  get formValue(): SignUpFormValue {
    return this.form.value as SignUpFormValue;
  }

  set formValue(formValue: SignUpFormValue) {
    this.form.setValue(formValue);
  }

  constructor(private store: Store<BaseAppState>) {}

  ngOnInit() {
    if (this.preFilledEmail) {
      this.form.get('emailAddress')?.setValue(this.preFilledEmail);
    }
  }

  handleSignUpClick(): void {
    if (!this.form.valid) {
      return;
    }

    this.signUpEmitter.emit(this.formValue);
  }

  handleLoginClick(): void {
    this.store.dispatch(goToLogin());
  }

  handlePrivacyPolicyClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.store.dispatch(ModalsActions.openPrivacyPolicyModal());
  }

  handleConsentAgreementClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.store.dispatch(ModalsActions.openConsentAgreementModal());
  }
}
