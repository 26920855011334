import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { SignUpFormValue } from '../sign-up-form/sign-up-form-value.model';
import { combineLatest } from 'rxjs';
import { AddToSubmissionRequest, AuthSelectors, BaseAppState, OptionalApplicationFeature, SignUpRequest, SignUpWithSubmissionRequest, SurveySelectors, getQueryParams, signUp, signUpWithSubmission } from 'ngx-esprio-shared';
import { environment } from 'projects/nutrition/src/environments/environment';

@Component({
  selector: 'app-sign-up-page',
  templateUrl: './sign-up-page.component.html',
})
export class SignUpPageComponent implements OnInit {
  public consultantConnectionDisabled: boolean;
  public signUpPageDisabled: boolean;
  readonly asyncProcessKeys = [signUp.type];

  queryParams$ = this.store.select(getQueryParams);
  private anonymousAnswers$ = this.store.select(SurveySelectors.getAnonymousAnswers);
  private coreData$ = this.store.select(AuthSelectors.getUserCoreData);

  // filled from query
  preFilled = {
    patientUserId: '',
    email: '',
  };

  constructor(
    public store: Store<BaseAppState>) {}

  ngOnInit() {
    this.queryParams$.pipe(take(1)).subscribe((queryParams) => {
      this.preFilled.patientUserId = queryParams.patientUserId;
      this.preFilled.email = queryParams.email;
      this.consultantConnectionDisabled = environment.disabledAppFeatures.includes(OptionalApplicationFeature.ConsultantConnection);
      this.signUpPageDisabled = environment.disabledAppFeatures.includes(OptionalApplicationFeature.SignUpPage);
    });
  }

  handleSignUpClick(formValue: SignUpFormValue): void {
    const request: SignUpRequest = {
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      email: formValue.emailAddress,
      password: formValue.password,
      patientUserId: this.preFilled.patientUserId,
    };

    combineLatest([this.coreData$.pipe(take(1)), this.anonymousAnswers$.pipe(take(1))]).subscribe(
      ([coreData, surveyData]) => {
        if (coreData && surveyData && Object.keys(surveyData).length > 0) {
          const submissions: AddToSubmissionRequest[] = Object.keys(surveyData).map((groupId) =>
            this.transformSurveyData(groupId, surveyData[groupId])
          );

          const signUpWithSubmissionRequest: SignUpWithSubmissionRequest = {
            signUpRequest: request,
            coreData: coreData,
            submissions: submissions,
          };
          this.store.dispatch(signUpWithSubmission({ payload: signUpWithSubmissionRequest }));
        } else {
          this.store.dispatch(signUp({ payload: request }));
        }
      }
    );
  }

  handleSignUpMonitoringApp(event: Event) {
    event.preventDefault();
    window.location.href = `${environment.nutritionMonitoringApp}/auth/sign-up`;
  }

  private transformSurveyData(groupId: string, answers: any[]): AddToSubmissionRequest {
    return {
      groupId,
      answers: answers.map((answer) => ({
        surveyElementId: answer.surveyElementId,
        values: answer.values,
      })),
    };
  }
}
