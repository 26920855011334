import { Component } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { WizardStepBaseComponent } from '../wizard-step-base/wizard-step-base.component';

@Component({
  selector: 'app-personal-info-wizard-step',
  templateUrl: './personal-info-wizard-step.component.html',
  styleUrls: ['./personal-info-wizard-step.component.scss']
})
export class PersonalInfoWizardStepComponent extends WizardStepBaseComponent {
  public readonly datepickerConfig: Partial<BsDatepickerConfig> = {
    showWeekNumbers: false,
    dateInputFormat: 'DD.MM.YYYY',
    useUtc: true,
  };
}
