<form
  [formGroup]="form"
  name="sign-up"
  (ngSubmit)="handleSignUpClick()"
  #ngForm="ngForm"
  novalidate
>
  <div class="mb-2">
    <label for="firstName" class="form-label">{{ 'auth.forms.first_name' | translate }}</label>
    <input
      id="firstName"
      name="firstName"
      type="text"
      autocomplete="given-name"
      class="form-control form-control-lg"
      formControlName="firstName"
      [class.is-invalid]="ngForm.submitted && form.controls['firstName'].invalid"
    />

    <div class="invalid-feedback">
      <div *ngIf="form.controls['firstName'].hasError('required')">
        {{ 'auth.forms.first_name_required_error' | translate }}
      </div>
    </div>
  </div>

  <div class="mb-2">
    <label for="lastName" class="form-label">{{ 'auth.forms.last_name' | translate }}</label>
    <input
      id="lastName"
      name="lastName"
      type="text"
      autocomplete="family-name"
      class="form-control form-control-lg"
      formControlName="lastName"
      [class.is-invalid]="ngForm.submitted && form.controls['lastName'].invalid"
    />

    <div class="invalid-feedback">
      <div *ngIf="form.controls['lastName'].hasError('required')">
        {{ 'auth.forms.last_name_required_error' | translate }}
      </div>
    </div>
  </div>

  <div class="mb-2">
    <label for="emailAddress" class="form-label">{{
      'auth.forms.email_address' | translate
    }}</label>
    <input
      id="emailAddress"
      name="emailAddress"
      type="email"
      autocomplete="username"
      class="form-control form-control-lg"
      formControlName="emailAddress"
      [readOnly]="!!preFilledEmail"
      [class.is-invalid]="ngForm.submitted && form.controls['emailAddress'].invalid"
    />

    <div class="invalid-feedback">
      <div *ngIf="form.controls['emailAddress'].hasError('required')">
        {{ 'auth.forms.email_required_error' | translate }}
      </div>
      <div *ngIf="form.controls['emailAddress'].hasError('pattern')">
        {{ 'auth.forms.email_invalid_error' | translate }}
      </div>
    </div>
  </div>

  <div class="mb-4">
    <label for="password" class="form-label">{{ 'auth.forms.password' | translate }}</label>
    <app-password-input
      name="password"
      [class.is-invalid]="ngForm.submitted && form.controls['password'].invalid"
      [isInvalid]="ngForm.submitted && form.controls['password'].invalid"
      controlName="password"
    ></app-password-input>
  </div>

  <div class="mb-4">
    <label for="passwordConfirmation" class="form-label">{{
      'auth.forms.password_confirmation' | translate
    }}</label>
    <app-password-input
      name="passwordConfirmation"
      [class.is-invalid]="ngForm.submitted && form.controls['passwordConfirmation'].invalid"
      [isInvalid]="ngForm.submitted && form.controls['passwordConfirmation'].invalid"
      controlName="passwordConfirmation"
    ></app-password-input>

    <div
      class="alert"
      *ngIf="ngForm.submitted && form.controls['password'].invalid; then error; else info"
    ></div>
  </div>

  <div class="form-check mb-4">
    <input
      class="form-check-input"
      type="checkbox"
      id="privacyPolicy"
      formControlName="hasAcknowledgedPrivacyPolicy"
    />
    <label class="form-check-label" for="privacyPolicy">
      {{ 'auth.signup.acknowledge_privacy_policy_1' | translate
      }}<a href="#" (click)="handlePrivacyPolicyClick($event)">{{
        'auth.signup.privacy_policy' | translate
      }}</a
      >{{ 'auth.signup.acknowledge_privacy_policy_2' | translate }}
    </label>

    <div
      class="invalid-feedback d-block"
      [class.d-block]="ngForm.submitted && form.controls['hasAcknowledgedPrivacyPolicy'].invalid"
    >
      <div *ngIf="form.controls['hasAcknowledgedPrivacyPolicy'].hasError('required')">
        {{ 'auth.signup.privacy_policy_ack_required_error' | translate }}
      </div>
    </div>
  </div>

  <div class="form-check mb-4">
    <input
      class="form-check-input"
      type="checkbox"
      id="disclaimer"
      formControlName="hasAcknowledgedDisclaimer"
    />
    <label class="form-check-label" for="disclaimer">
      {{ 'auth.signup.acknowledge_disclaimer_1' | translate
      }}<a href="#" (click)="handleConsentAgreementClick($event)">{{
        'auth.signup.disclaimer' | translate
      }}</a
      >{{ 'auth.signup.acknowledge_disclaimer_2' | translate }}
    </label>

    <div
      class="invalid-feedback d-block"
      [class.d-block]="ngForm.submitted && form.controls['hasAcknowledgedDisclaimer'].invalid"
    >
      <div *ngIf="form.controls['hasAcknowledgedDisclaimer'].hasError('required')">
        {{ 'auth.signup.disclaimer_ack_required_error' | translate }}
      </div>
    </div>
  </div>

  <div class="mb-4">
    <button type="submit" class="btn btn-lg btn-primary w-100">
      {{ 'auth.signup.signup' | translate }}
    </button>
  </div>
</form>

<ng-template #info>
  <div
    class="alert alert-primary"
    *ngIf="!ngForm.submitted || !form.controls['passwordConfirmation'].value"
  >
    <img class="me-3" src="assets/images/InfoIcon.svg" />{{
      'auth.forms.password_pattern_error' | translate
    }}
  </div>
</ng-template>

<ng-template #error>
  <div class="alert alert-danger">
    <img class="me-3" src="assets/images/DangerIcon.svg" />
    <div>
      <div *ngIf="form.controls['password'].hasError('required')">
        {{ 'auth.forms.password_required_error' | translate }}
      </div>
      <div *ngIf="form.controls['passwordConfirmation'].hasError('required')">
        {{ 'auth.forms.password_required_error' | translate }}
      </div>
      <div *ngIf="!form.controls['password'].hasError('required')">
        <div *ngIf="form.controls['password'].hasError('minlength')">
          {{ 'auth.forms.password_length_error' | translate }}
        </div>
        <div *ngIf="form.controls['password'].hasError('uppercase')">
          {{ 'auth.forms.password_uppercase_error' | translate }}
        </div>
        <div *ngIf="form.controls['password'].hasError('lowercase')">
          {{ 'auth.forms.password_lowercase_error' | translate }}
        </div>
        <div *ngIf="form.controls['password'].hasError('number')">
          {{ 'auth.forms.password_number_error' | translate }}
        </div>
        <div *ngIf="form.controls['password'].hasError('special')">
          {{ 'auth.forms.password_special_error' | translate }}
        </div>
      </div>
      <div *ngIf="!form.controls['passwordConfirmation'].hasError('required')">
        <div *ngIf="form.controls['passwordConfirmation'].hasError('minlength')">
          {{ 'auth.forms.password_length_error' | translate }}
        </div>
        <div *ngIf="form.controls['passwordConfirmation'].hasError('uppercase')">
          {{ 'auth.forms.password_uppercase_error' | translate }}
        </div>
        <div *ngIf="form.controls['passwordConfirmation'].hasError('lowercase')">
          {{ 'auth.forms.password_lowercase_error' | translate }}
        </div>
        <div *ngIf="form.controls['passwordConfirmation'].hasError('number')">
          {{ 'auth.forms.password_number_error' | translate }}
        </div>
        <div *ngIf="form.controls['passwordConfirmation'].hasError('special')">
          {{ 'auth.forms.password_special_error' | translate }}
        </div>
        <div *ngIf="form.controls['passwordConfirmation'].hasError('passwordMismatch')">
          {{ 'auth.forms.password_confirmation_mismatch_error' | translate }}
        </div>
      </div>
    </div>
  </div>
</ng-template>
