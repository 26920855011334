import { Routes } from '@angular/router';
import { ConfirmInvitationComponent, LoggedInGuard, PatientsPageComponent, RelativeAccountManagerComponent } from 'ngx-esprio-shared';

export const RELATIVE_ACCOUNT_PATH_ELEMENTS = {
  root: 'relative-account',
  elements: {
    managerElement: `manager`,
    confirmInvitationElement: 'confirm-invitation',
    patientsElement: 'patients',
  },
};

export const RELATIVE_ACCOUNT_PATHS = {
  relativeManager: `/${RELATIVE_ACCOUNT_PATH_ELEMENTS.root}/${RELATIVE_ACCOUNT_PATH_ELEMENTS.elements.managerElement}`,
  confirmInvitation: `/${RELATIVE_ACCOUNT_PATH_ELEMENTS.root}/${RELATIVE_ACCOUNT_PATH_ELEMENTS.elements.confirmInvitationElement}`,
  patients: `/${RELATIVE_ACCOUNT_PATH_ELEMENTS.root}/${RELATIVE_ACCOUNT_PATH_ELEMENTS.elements.patientsElement}`,
};

export const relativeAccountRoutes: Routes = [
  {
    path: RELATIVE_ACCOUNT_PATH_ELEMENTS.root,
    children: [
      {
        path: RELATIVE_ACCOUNT_PATH_ELEMENTS.elements.managerElement,
        component: RelativeAccountManagerComponent,
        canActivate: [LoggedInGuard],
      },
      {
        path: RELATIVE_ACCOUNT_PATH_ELEMENTS.elements.confirmInvitationElement,
        component: ConfirmInvitationComponent,
        canActivate: [LoggedInGuard],
      },
      {
        path: RELATIVE_ACCOUNT_PATH_ELEMENTS.elements.patientsElement,
        component: PatientsPageComponent,
        canActivate: [LoggedInGuard],
      },
    ],
  },
];
