<div class="d-grid gap-1 w-100">
  <div *ngFor="let option of options; let i = index; trackBy: trackByIndex">
    <input
      class="btn-check"
      type="checkbox"
      autocomplete="off"
      [id]="'answers-checkbox-' + i"
      [checked]="isOptionSelected(option)"
    />
    <label
      class="btn btn-primary btn-lg w-100"
      [for]="'answers-checkbox-' + i"
      (click)="selectOption($event, option)"
    >
      {{ option.titleKey | translate }}</label
    >
  </div>
</div>
