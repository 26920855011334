import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { BaseAppState, ModalsActions, RelativeAccountActions, SYMPTOMS_QUESTION_GROUPS, SurveyApiActions, SurveySelectors, WeightHistoryApiActions, WeightHistorySelectors, getIsRelativeUserAccount, goToSurvey } from 'ngx-esprio-shared';
import { BehaviorSubject, take } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  public readonly asyncProcessKeys = [SurveyApiActions.createSubmission.type];

  public weightData$ = this.store.select(WeightHistorySelectors.getData);
  public symptomsRedData$ = this.store.select(
    SurveySelectors.getSymptomsHistoryData(SYMPTOMS_QUESTION_GROUPS.RED_PRO_CTCAE_GROUP)
  );
  public symptomsYellowData$ = this.store.select(
    SurveySelectors.getSymptomsHistoryData(SYMPTOMS_QUESTION_GROUPS.YELLOW_PRO_CTCAE_GROUP)
  );
  public generalHealthData$ = this.store.select(SurveySelectors.getGeneralHealthHistoryData);
  public date$ = new BehaviorSubject<Date>(moment().startOf('month').toDate());
  public dataAvailability$ = this.store.select(SurveySelectors.getDataAvailability);
  public isRelativeAccount$ = this.store.select(getIsRelativeUserAccount);

  public date = moment().startOf('month').toDate();

  constructor(private store: Store<BaseAppState>) {}

  public ngOnInit(): void {
    this.fetchChartData();
    this.loadPatientsData();
    this.store.dispatch(SurveyApiActions.checkDataAvailability());
  }

  ngAfterViewInit() {
    this.store.dispatch(ModalsActions.openAddWeightDataModalIfSubmissionNeeded());
  }

  public handleDateChange(date: Date): void {
    this.date$.next(date);
    this.fetchChartData();
  }

  public handleNewSurveyClick(): void {
    this.store.dispatch(SurveyApiActions.createSubmission());
  }

  public handleGoToSurveyAnalysis(submissionId: string): void {
    this.store.dispatch(goToSurvey({ submissionId }));
  }

  public fetchChartData(): void {
    this.store.dispatch(WeightHistoryApiActions.getWeightHistoryData());
    this.store.dispatch(
      SurveyApiActions.getSymptomsHistoryData({
        questionGroup: SYMPTOMS_QUESTION_GROUPS.RED_PRO_CTCAE_GROUP,
      })
    );
    this.store.dispatch(
      SurveyApiActions.getSymptomsHistoryData({
        questionGroup: SYMPTOMS_QUESTION_GROUPS.YELLOW_PRO_CTCAE_GROUP,
      })
    );
    this.store.dispatch(SurveyApiActions.getGeneralHealthHistoryData());
  }

  private loadPatientsData(): void {
    this.isRelativeAccount$.pipe(take(1)).subscribe((isRelativeAccount) => {
      if (isRelativeAccount) {
        this.store.dispatch(RelativeAccountActions.getRelativePatients());
      }
    });
  }
}
