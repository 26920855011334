import { Component } from '@angular/core';
import { AUTH_UI_PATHS } from 'ngx-esprio-shared';

@Component({
  selector: 'app-auth-navigation',
  templateUrl: 'auth-navigation.component.html',
})
export class AuthNavigationComponent {
  readonly AUTH_UI_PATHS = AUTH_UI_PATHS;
}
