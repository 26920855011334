import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AuthNavigationComponent } from './auth-navigation/auth-navigation.component';
import { routes } from './auth-ui.routes';
import { EditUserCoreDataPageComponent } from './edit-user-core-data-page/edit-user-core-data-page.component';
import { MultiOptionWizardStepComponent } from './edit-user-core-data-page/wizard/multi-option-wizard-step/multi-option-wizard-step.component';
import { PersonalInfoWizardStepComponent } from './edit-user-core-data-page/wizard/personal-info-wizard-step/personal-info-wizard-step.component';
import { EditUserCoreDataWizardComponent } from './edit-user-core-data-page/wizard/user-core-data-wizard/user-core-data-wizard.component';
import { LoginFormComponent } from './login/login-form/login-form.component';
import { LoginPageComponent } from './login/login-page/login-page.component';
import { SignUpConfirmPageComponent } from './sign-up-confirm-page/sign-up-confirm-page.component';
import { SignUpSuccessfulPageComponent } from './sign-up-successful-page/sign-up-successful-page.component';
import { SignUpFormComponent } from './sign-up/sign-up-form/sign-up-form.component';
import { SignUpPageComponent } from './sign-up/sign-up-page/sign-up-page.component';
import { AsyncStateOverlayModule, AuthUIModule, CustomTranslatorModule, SharedModule } from 'ngx-esprio-shared';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    CdkStepperModule,
    BsDatepickerModule,
    ModalModule,
    AsyncStateOverlayModule,
    CustomTranslatorModule,
    SharedModule,
    AuthUIModule,
  ],
  declarations: [
    MultiOptionWizardStepComponent,
    PersonalInfoWizardStepComponent,
    EditUserCoreDataWizardComponent,
    EditUserCoreDataPageComponent,
    LoginFormComponent,
    LoginPageComponent,
    SignUpFormComponent,
    SignUpPageComponent,
    SignUpConfirmPageComponent,
    SignUpSuccessfulPageComponent,
    AuthNavigationComponent,
  ],
})
export class AppAuthUIModule {}
