<div class="container-md">
  <!-- title -->
  <h2 class="text-white mb-2">{{ 'dashboard.title' | translate }}</h2>

  <!-- available surveys carousel -->
  <app-surveys></app-surveys>

  <!-- chart: general health -->
  <div *ngIf="(dataAvailability$ | async)?.hasGeneralHealthHistoryData" class="chart mt-2">
    <app-date-picker
      [date]="date$ | async"
      (dateChange)="handleDateChange($event)"
    ></app-date-picker>
    <app-general-health-chart
      [data]="generalHealthData$ | async"
      [date]="date$ | async"
    ></app-general-health-chart>
  </div>

  <!-- chart: symptoms Scale -->
  <div *ngIf="(dataAvailability$ | async)?.hasSymptomsHistoryDataScale" class="chart mt-2">
    <app-date-picker
      [date]="date$ | async"
      (dateChange)="handleDateChange($event)"
    ></app-date-picker>
    <app-symptoms-history-chart
      [data]="symptomsRedData$ | async"
      [date]="date$ | async"
    ></app-symptoms-history-chart>
  </div>

  <!-- chart: symptoms Yes / No-->
  <div *ngIf="(dataAvailability$ | async)?.hasSymptomsHistoryDataYesNo" class="chart mt-2">
    <app-date-picker
      [date]="date$ | async"
      (dateChange)="handleDateChange($event)"
    ></app-date-picker>
    <app-symptons-yes-no-bar-chart
      [data]="(symptomsYellowData$ | async)!"
      [date]="date$ | async"
    ></app-symptons-yes-no-bar-chart>
  </div>

  <!-- chart: weight -->
  <div class="chart mt-2">
    <app-date-picker
      [date]="date$ | async"
      (dateChange)="handleDateChange($event)"
    ></app-date-picker>
    <app-weight-chart [data]="weightData$ | async" [date]="date$ | async"></app-weight-chart>
  </div>

  <!-- info carousel -->
  <div class="dashboard-carousel mt-2">
    <app-dashboard-carousel></app-dashboard-carousel>
  </div>

  <!-- chart: previous surveys -->
  <h2 class="text-white mb-2">{{ 'dashboard.previous_submissions.title' | translate }}</h2>
  <div class="mb-5">
    <app-survey-list (surveyClick)="handleGoToSurveyAnalysis($event)"></app-survey-list>
  </div>
  <button disabled class="btn btn-secondary btn-lg w-100 mb-5">
    {{ 'dashboard.load_more' | translate }}
  </button>
</div>

<app-async-state-overlay [processKeys]="asyncProcessKeys"></app-async-state-overlay>
