import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { LoginFormValue } from './login-form-value.model';
import { BaseAppState, emailPatternValidator, goToRequestPasswordReset, goToSignUp } from 'ngx-esprio-shared';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent {
  @Output('login') loginEmitter = new EventEmitter<LoginFormValue>();

  form: FormGroup = new FormGroup({
    emailAddress: new FormControl('', [Validators.required, emailPatternValidator]),
    password: new FormControl('', Validators.required),
  });

  constructor(private store: Store<BaseAppState>) {}

  handleLoginClick(): void {
    if (!this.form.valid) {
      return;
    }

    this.loginEmitter.emit(this.form.value);
  }

  handleSignUpClick(): void {
    this.store.dispatch(goToSignUp());
  }

  handleResetClick(event: MouseEvent): void {
    event.preventDefault();
    this.store.dispatch(goToRequestPasswordReset());
  }
}
